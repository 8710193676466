import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ClientModuleTemplateDefault, ModuleDefaultConfiguration } from '../../../models/ClientModuleDefaults';
import { Access } from '../../../models/Access';
import ManageClassesStep from './ManageClassesStep';
import ManageClassStep from './ManageClassStep';
import { ManageDocumentClassesContext } from '../../../contexts/ManageDocumentClassesContext';
import User from '../../../models/User';
import CreateAccessStep from './CreateAccessStep';
import CreateClassStep from './CreateClassStep';
import StartCreateClassStep from './StartCreateClassStep';
import WelcomeStep from './WelcomeStep';
import { ModuleTemplate, ModuleType } from '../../../models/Module';
import ModuleUtils from '../../../utils/ModuleUtils';
import ImportClassStep from './ImportClassesStep';
import CreatePeriodicReviewStep from './CreatePeriodicReviewStep';
import CreateDownloadPreferencesStep from './CreateDownloadPreferencesStep';
import CreateClassConfigStep from './CreateClassConfigStep';
import { useCurrentClient } from '../../../global-state/Clients';

export const manageClassesWizardSteps = [
  'welcome',
  'start',
  'importClass',
  'createClass',
  'createAccess',
  'manageClasses',
  'details',
  'createPeriodicReview',
  'createDownloadPdfPreferences',
  'createClassConfig',
] as const;

export type ManageClassesWizardStep = (typeof manageClassesWizardSteps)[number];

type Props = {
  classes: ClientModuleTemplateDefault[];
  selectedClassDefault?: ClientModuleTemplateDefault | null;
  onCancel: () => void;
  activeStep: ManageClassesWizardStep;
  clientUsers: User[];
  onCreated: (newClass: ModuleTemplate) => void;
  onUpdated: (updatedClass: ClientModuleTemplateDefault) => void;
  onUserInvited: () => void;
};

const ManageClassesWizard: FC<Props> = (props) => {
  const { onCancel, activeStep, classes, clientUsers, onUpdated, onCreated, selectedClassDefault, onUserInvited } = props;
  const currentClient = useCurrentClient((x) => x.value);

  const steps = useMemo<Record<ManageClassesWizardStep, JSX.Element>>(() => {
    return {
      welcome: <WelcomeStep />,
      start: <StartCreateClassStep />,
      importClass: <ImportClassStep />,
      createClass: <CreateClassStep />,
      createClassConfig: <CreateClassConfigStep />,
      createAccess: <CreateAccessStep />,
      manageClasses: <ManageClassesStep />,
      details: <ManageClassStep />,
      createPeriodicReview: <CreatePeriodicReviewStep />,
      createDownloadPdfPreferences: <CreateDownloadPreferencesStep />,
    };
  }, []);

  const [selectedClass, setSelectedClass] = useState<ClientModuleTemplateDefault | null>(selectedClassDefault || null);
  const [newTemplate, setTemplate] = useState<ModuleTemplate>(ModuleUtils.NewTemplate(currentClient?.id, ModuleType.Document, 0, ''));
  const [defaults, setDefaults] = useState<ModuleDefaultConfiguration>({ accessType: Access.restricted, users: [] });

  const reset = useCallback(() => {
    setSelectedClass(null);
    setTemplate(ModuleUtils.NewTemplate(currentClient?.id, ModuleType.Document, 0, ''));
    setDefaults({ accessType: Access.restricted, users: [] });
  }, [currentClient?.id]);

  const [stepStack, setStepStack] = useState([steps[activeStep]]);
  const nextStep = useCallback(
    (name: ManageClassesWizardStep) => {
      setStepStack((prev) => [...prev, steps[name]]);
    },
    [steps],
  );

  useEffect(() => {
    nextStep(activeStep);
  }, [activeStep, nextStep]);

  useEffect(() => {
    setSelectedClass(selectedClassDefault || null);
  }, [selectedClassDefault]);

  const prevStep = useCallback(() => {
    setStepStack((prev) => prev.filter((_, i) => i !== prev.length - 1));
  }, []);

  const contextValues = useMemo(
    () => ({
      classes,
      newTemplate,
      setTemplate,
      defaults,
      setDefaults,
      nextStep,
      prevStep,
      cancel: onCancel,
      stepNames: manageClassesWizardSteps,
      clientUsers,
      selectedClass,
      setSelectedClass,
      onCreated,
      onUpdated,
      reset,
      onUserInvited,
    }),
    [classes, clientUsers, defaults, newTemplate, nextStep, onCancel, onCreated, onUpdated, onUserInvited, prevStep, reset, selectedClass],
  );

  return <ManageDocumentClassesContext.Provider value={contextValues}>{stepStack[stepStack.length - 1]}</ManageDocumentClassesContext.Provider>;
};

export default ManageClassesWizard;
