import StandardModal from '../../shared/modal/variants/StandardModal';
import { useTranslation } from 'react-i18next';
import { useManageDocumentClassesWizard } from '../../../contexts/ManageDocumentClassesContext';
import { SearchInput } from '../../shared/form-control/SearchInput';
import { InputStyle } from '../../shared/form-control/Input';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Checkbox from '../../shared/form-control/Checkbox';
import TemplateModuleService from '../../../services/TemplateModuleService';
import { ModuleTemplate, ModuleType } from '../../../models/Module';
import ClientTemplateModuleService from '../../../services/ClientTemplateModuleService';
import { Heading, HeadingSize } from '../../shared/text/Heading';
import DocumentSpaceIcon from '../../shared/icon/DocumentSpaceIcon';
import Button, { ButtonType } from '../../shared/form-control/Button';
import Loader from '../../shared/Loader';
import LanguageUtils from '../../../utils/LanguageUtils';
import { useCurrentClient } from '../../../global-state/Clients';

type SelectableModuleTemplate = ModuleTemplate & { selected: boolean; imported: boolean };

const ImportClassStep = () => {
  const { nextStep, prevStep, onCreated, classes } = useManageDocumentClassesWizard();
  const { t } = useTranslation(['documents', 'common']);
  const [searchQuery, setSearchQuery] = useState('');
  const [serviceProviderClasses, setServiceProviderClasses] = useState<SelectableModuleTemplate[]>([]);
  const [filteredServiceProviderClasses, setFilteredServiceProviderClasses] = useState<SelectableModuleTemplate[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const currentClient = useCurrentClient((x) => x.value);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    TemplateModuleService.getTemplates(false, undefined, [ModuleType.Document])
      .then((res) => {
        setServiceProviderClasses(
          res.data.map((moduelTemplate) => ({
            ...moduelTemplate,
            selected: false,
            imported: !!classes.find((item) => item.templateModule.id === moduelTemplate.id),
          })),
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [classes]);

  useEffect(() => {
    setFilteredServiceProviderClasses(
      serviceProviderClasses.filter((x) => {
        const query = searchQuery.toLowerCase();
        const templateName = LanguageUtils.getTranslation('name', x.translations).toLowerCase();
        return templateName.includes(query);
      }),
    );
  }, [searchQuery, serviceProviderClasses]);

  const toggleSelectAll = (value: boolean) => {
    setSelectAll(value);
    const updatedItems = filteredServiceProviderClasses.map((item) => (!item.imported ? { ...item, selected: !selectAll } : item));
    setFilteredServiceProviderClasses(updatedItems);
  };

  const toggleSelectItem = (id: string) => {
    const updatedItems = filteredServiceProviderClasses.map((item) =>
      item.id === id && !item.imported ? { ...item, selected: !item.selected } : item,
    );
    setFilteredServiceProviderClasses(updatedItems);
    setSelectAll(updatedItems.filter((item) => !item.imported).every((item) => item.selected));
  };

  const canImport = useMemo(() => {
    return filteredServiceProviderClasses.some((item) => item.selected);
  }, [filteredServiceProviderClasses]);

  const importClasses = useCallback(() => {
    if (currentClient) {
      setIsSaving(true);
      new ClientTemplateModuleService(currentClient.id)
        .importTemplatesAndInstantiate(filteredServiceProviderClasses.filter((item) => !item.imported && item.selected).map((item) => item.id))
        .then(() => {
          nextStep && nextStep('manageClasses');
          onCreated(null!);
        })
        .finally(() => setIsSaving(false));
    }
  }, [currentClient, filteredServiceProviderClasses, nextStep, onCreated]);

  const noClassesToImport = useMemo(() => serviceProviderClasses.length === 0, [serviceProviderClasses]);

  return (
    <StandardModal
      title={t('manage-wizard.import.title')}
      cancelButtonTitle={t('manage-wizard.buttons.back')}
      onCancelClick={prevStep}
      confirmButtonTitle={t('manage-wizard.buttons.import')}
      confirmDisabled={!canImport}
      onConfirmClick={importClasses}
      confirmLoading={isSaving}
      tertiaryButtonIcon={null}
    >
      <div className="h-[60vh] flex-grow overflow-auto px-1">
        {isLoading && <Loader centered size={16} />}
        {!noClassesToImport && !isLoading && (
          <div className="flex justify-between gap-2">
            <div className="flex gap-2">
              <Checkbox label={t('manage-wizard.import.select-all')} labelClass="font-medium" value={selectAll} onChange={toggleSelectAll} />
            </div>
            <div className="w-96">
              <SearchInput
                data-cy="doc-class-search"
                placeholder={t('common:list.filter.search')}
                style={InputStyle.MINIMAL}
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
              />
            </div>
          </div>
        )}
        {noClassesToImport && !isLoading && (
          <div className="flex w-full justify-center text-center">
            <div className="flex h-[55vh] w-4/5 flex-col items-center justify-center">
              <DocumentSpaceIcon className="bg-background-1 mt-5 h-20 w-20 rounded-full p-4" />
              <Heading size={HeadingSize.H4} className="mt-4">
                {t('manage-wizard.import.empty.title')}
              </Heading>
              <p className="mb-8">{t('manage-wizard.import.empty.description')}</p>
              <Button type={ButtonType.SECONDARY} onClick={() => nextStep && nextStep('createClass')}>
                {t('manage-wizard.buttons.add')}
              </Button>
            </div>
          </div>
        )}
        <div className="mt-8">
          {filteredServiceProviderClasses.map((moduleTemplate) => (
            <div key={moduleTemplate.id} className="flex items-start gap-4 pb-4">
              <div className="-my-1">
                <Checkbox
                  value={moduleTemplate.selected || false}
                  onChange={() => toggleSelectItem(moduleTemplate.id)}
                  disabled={moduleTemplate.imported}
                />
              </div>
              <div className={`${moduleTemplate.imported ? 'opacity-50' : ''}`}>
                <span className="font-medium">{LanguageUtils.getTranslation('name', moduleTemplate.translations || {})}</span>
                <p>{LanguageUtils.getTranslation('description', moduleTemplate.translations || {})}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </StandardModal>
  );
};

export default ImportClassStep;
